@media screen and (max-width: 800px) {
    .main .golf-estates .services-button-wrapper {
        position: absolute;
        top: 24px;
        left: 78px;
    }
}

@media screen and (max-width: 800px) {
    .golf-estates {
        .golf-estates-switches {
            width: 300px !important;

            left: 50% !important;
            bottom: 110px !important;

            top: auto !important;
            right: auto !important;

            transform: translateX(-50%) !important;

            flex-direction: row !important;
            gap: 4px;

            .switch {
                width: 150px !important;
            }
        }

        .pop-up {
            flex-direction: column;

            right: auto !important;
            top: auto !important;

            left: 50%;
            transform: translateX(-50%);
            bottom: 150px;

            width: 96%;

            .pop-up-box {
                .pop-up-box-title {
                    font-size: 14px;
                }
                .pop-up-box-desc {
                    font-size: 11px;
                }
            }

            .pop-up-triangle {
                transform: rotate(90deg) translateX(-2.35px);
            }
        }
    }
}

@media screen and (max-width: 302px) {
    .golf-estates {
        .golf-estates-switches {
            flex-direction: column !important;
            width: auto !important;
        }
    }
}
