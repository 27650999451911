@media screen and (max-height: 500px) {
    .main {
        .back-button {
            position: absolute;
            z-index: 3;
    
            width: 100px;
            height: 37px;
    
            left: auto;
            right: 20px;
            top: 50px;
    
            background-color: #606E71;
    
            font-family: Inter;
            font-size: 12px;
            font-weight: 300;
            color: #fff;
            letter-spacing: 2px;
            text-decoration: none;
            text-transform: uppercase;
    
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            padding: 0 18px 0 18px;
            box-sizing: border-box;
    
            border-radius: 4px;
    
            @media (max-aspect-ratio: 1.777777777777778) {
                transform: translateY(-8px);
            }
    
            img {
                width: 12px;
                margin: 0 6px 0 0;
            }
        }
    }
}
@media screen and (max-width: 1000px){
    .main {
        .back-button {
            position: absolute;
            z-index: 3;
    
            width: 100px;
            height: 37px;
    
            left: auto;
            right: 20px;
            top: 50px;
    
            background-color: #606E71;
    
            font-family: Inter;
            font-size: 12px;
            font-weight: 300;
            color: #fff;
            letter-spacing: 2px;
            text-decoration: none;
            text-transform: uppercase;
    
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            padding: 0 18px 0 18px;
            box-sizing: border-box;
    
            border-radius: 4px;
    
            @media (max-aspect-ratio: 1.777777777777778) {
                transform: translateY(-8px);
            }
    
            img {
                width: 12px;
                margin: 0 6px 0 0;
            }
        }
    }
}
@media screen and (max-width: 1420px){
    .main {
        .back-button {
            width: 70px;
            height: 25px;

            padding: 12px 8px;
            img{
                margin: 0;
                width: 10px;
            }
        }
    }
}