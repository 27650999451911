@media screen and (max-height: 400px) and (min-width: 600px) {
    .main {
        .contact-form {
            width: 94dvw !important;
            height: 320px !important;
            max-height: 98vh !important;

            padding: 20px 18px 20px 18px;

            .contact-form-separator {
                height: 100%;

                img {
                    height: 100%;
                }
            }

            .contact-form-left {
                min-height: auto !important;
                gap: 10px;

                min-width: 350px;

                .contact-form-left-group {
                    width: 100%;

                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: space-between;
                }

                .contact-form-position-info {
                    width: 100%;
                    flex-wrap: wrap;
                    height: 50px !important;

                    .contact-form-info {
                        width: 50% !important;
                    }
                }

                .contact-form-position-info-nsew {
                    width: 80% !important;
                }
            }

            .contact-form-info {
                width: 80% !important;
            }

            .contact-form-text-color {
                color: #cc7d61;
                text-transform: none;
            }

            .contact-form-text-transformer {
                width: 220px !important;
            }

            .contact-form-right {
                width: 250px;
            }

            form {
                gap: 5px;

                .contact-form-submit {
                    height: 35px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .main {
        .contact-form {
            position: absolute !important;
            top: 70px;

            width: 90% !important;

            flex-direction: column-reverse !important;
            gap: 40px !important;

            .contact-form-separator {
                display: none !important;
            }

            .contact-form-left {
                width: 95% !important;
                height: auto !important;

                min-height: auto !important;
            }

            .contact-form-right {
                width: 95% !important;
                height: auto !important;
            }
        }
    }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
    .main{
        .contact-form{
            .contact-form-left{
                img{
                    width: 100%;
                    max-width: 400px;
                }
            }
        }
    }
}

@media screen and (max-height: 650) and (orientation: landscape) {
    .main{
        .contact-form{
            .contact-form-left{
                img{
                    width: 80%;
                    max-width: 400px;
                }
            }
        }
    }
}

@media screen and (max-height: 652px){
    .main{
        .contact-form{
            .contact-form-left{
                img{
                    width: 80%;
                    max-width: 400px;
                }
            }
        }
    }
}