.map {
    width: 100vw;
    height: 100dvh;

    .map-switches {
        position: absolute;
        z-index: 2;

        display: flex;
        align-items: flex-end;
        flex-direction: column;
        gap: 6px;

        right: 30px;
        top: 30px;
    }

    .map-container {
        width: 100%;
        height: 100%;

        .gm-style iframe + div {
            border: none !important;
        }

        .gm-style-iw {
            border-radius: 3px !important;

            .gm-style-iw-d {
                padding: 0 !important;

                .directions-infowindow {
                    font-family: Inter;
                    font-size: 11px;
                    font-weight: 700;
                    color: rgb(0, 0, 0);
                    padding: 0 !important;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        width: 15px;
                        margin-right: 10px;
                    }
                }
            }

            .gm-ui-hover-effect {
                display: none !important;
            }
        }
    }
}
