.main {
    .master-plan {
        .golf-estates-area {
            path {
                // fill: rgba(255, 255, 255, 0.3) !important;
                // stroke-width: 4 !important;
                stroke: #FFF !important;

                &:hover {
                    fill: rgba(255, 255, 255, 0.12) !important;
                }
            }
        }

        .golf-estates-icon {
            pointer-events: none !important;
        }

        .landmark {
            filter: drop-shadow(0px 0px 4px rgba(5, 5, 5, 0.25)) !important;
            opacity: 0%;
            transition: 0.2s;

            pointer-events: bounding-box !important;

            &:hover {
                opacity: 100%;
            }
        }
    }
}
