@media screen and (max-height: 400px) {
    .main {
        .reel {
            .reel-menu {
                left: 50% !important;
                transform: translateX(-50%);
                bottom: 40px !important;

                height: 50px !important;

                .controls {
                    justify-content: center !important;
                    gap: 0px;

                    background-color: #2e38384d !important;
                    transform: scale(0.8) !important;

                    .controls-compass {
                        transform: scale(0.9) !important;
                    }

                    .controls-compass-pointer {
                        top: -10px;

                        img {
                            width: 15px;
                        }
                    }

                    .controls-button {
                        border-radius: 100% !important;

                        width: 30px !important;
                        height: 30px !important;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) and (min-height: 400px) {
    .main {
        .reel {
            .reel-menu {
                left: 50% !important;
                transform: translateX(-50%);
                bottom: 45px !important;

                width: 100% !important;
                height: 50px !important;

                .controls {
                    width: 100% !important;
                    padding: 12px 30px 12px 30px;

                    justify-content: center !important;
                    gap: 0px;

                    border-radius: 0;

                    background-color: #2e38384d !important;

                    .controls-compass {
                        transform: scale(0.8) !important;
                    }

                    .controls-compass-pointer {
                        top: -10px;

                        img {
                            width: 15px;
                        }
                    }

                    .controls-button {
                        border-radius: 100% !important;

                        width: 30px !important;
                        height: 30px !important;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}
