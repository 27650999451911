.main {
    .svg-customs {
        svg {
            position: absolute;

            width: auto;
            height: 100%;

            @media (min-aspect-ratio: 1.777777777777778) {
                width: 100%;
                height: auto;
            }
            pointer-events: none;

            
            .svg-custom {
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;

                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;

                pointer-events: all;

                cursor: pointer;

            }

        }
    }
}
