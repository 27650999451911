.switch {
    max-width: 150px;
    width: 100%;
    height: 7px;

    display: flex;
    align-items: center;

    pointer-events: all;
    cursor: pointer;

    padding: 14px;
    background-color: #131818a1;

    border-radius: 40px;

    gap: 15px;

    .switch-label {
        font-size: 13px;
        color: #ffffff;
    }
}
