.main {
    .top-logo {
        position: absolute;

        pointer-events: none;

        display: flex;
        flex-direction: column;

        gap: 15px;

        z-index: 3;
    }

    .top-logo-niseko {
        user-select: none;

        img {
            margin: 15px 0 0 0;
            width: 240px;

            pointer-events: none;
        }
    }

    .top-logo-niseko-estates {
        left: 0;
        top: 0;
        .background-of-image{
            position: absolute;
            z-index: 0;
        }
        .top-logo-niseko-estates-image {
            z-index: 1;
            width: 200px;
            margin: 10px 0 0 15px;

            pointer-events: none;
        }
    }
}
