@media screen and (max-height: 400px) {
    .main .services-button {
        border: none;
        font-size: 13px;

        width: 107px;
        height: 32px;

        left: 15px !important;
        top: auto !important;
        bottom: 45px !important;
    }

    .main .services {
        transform: scale(0.7);
    }
}

@media screen and (max-width: 800px) {
    .main .services-button {
        border: none;
        font-size: 13px;

        width: 107px;
        height: 32px;

        left: auto !important;
        right: 130px !important;
        top: 18px !important;
    }
}

@media screen and (max-width: 700px) {
    .main .services .services-body {
        gap: 20px;
        justify-content: space-between;

        .service-item-name {
            max-width: 82px;
        }
    }
}

@media screen and (max-width: 450px) {
    .main .services .services-body {
        flex-wrap: wrap;
        justify-content: center;
    }
}
