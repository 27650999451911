@media screen and (max-width: 800px), screen and (max-height: 400px) {
    .main .top-logo-al-zumuruda {
        display: none !important;
    }
}

@media screen and (max-height: 400px) {
    .main .top-logo-alaqtar {
        img {
            width: 80px !important;
        }
    }
}

@media screen and (max-width: 800px) and (min-width: 655px) {
    .main .top-logo-alaqtar {
        top: auto !important;
        bottom: 85px !important;

        left: 20px !important;

        .service-button-wrapper {
            display: none !important;
        }

        img {
            width: 120px !important;
        }
    }
}

@media screen and (max-width: 655px) and (min-width: 310px) {
    .main .top-logo-alaqtar {
        top: auto !important;
        bottom: 120px !important;

        left: calc(50% - 156px) !important;

        img {
            width: 100px !important;
        }
    }
}

@media screen and (max-width: 310px) {
    .main .top-logo-alaqtar {
        top: auto !important;
        bottom: 155px !important;

        left: calc(50%) !important;
        transform: translateX(-50%) !important;

        img {
            width: 120px !important;
        }
    }
}
