.main {
    .vr-exit{
        border-radius: 50%;
        background-color: #FFFFFF4D;
        position: absolute;
        z-index: 5;

        top: 35px;
        right: 69px;

        cursor: pointer;

        display: flex;
        // align-items: center;
        // justify-content: center;

        gap: 10px;
        border-radius: 50%;
        border: none;
        padding: 13px;

        transition: 0.3s;

        svg {
            width: 20px;
            height: 20px;
        }
    }
    #id {
        width: 100%;
        height: 100vh;

        .hotspot {
            height: 30px !important;
            width: 30px !important;
            
            background: url("../../../static/images/contents/golf-estates/vr/material-icon.png") no-repeat center center !important;
            background-size: cover !important;
            border-radius: 100px !important;
        }
    }
}
