.main {
    .back-button {
        position: absolute;
        z-index: 3;

        width: 80px;
        height: 30px;

        left: 32px;
        top: 96px;

        background-color: #606E71;

        font-family: Inter;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 12px;
        font-weight: 300;
        color: #fff;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0 18px 0 18px;
        box-sizing: border-box;

        border-radius: 4px;

        cursor: pointer;
        transition: 0.2s ease-in-out;

        @media (max-aspect-ratio: 1.777777777777778) {
            transform: translateY(-8px);
        }

        img {
            width: 12px;
            margin: 0 4px 0 0;
        }

        &:hover {
            background-color: #afb9b9;
        }
    }
}
