.main {
    svg {
        .svg-label {
            pointer-events: none;

            .svg-label-background,
            .svg-label-text{
                opacity: 1;
                transition: opacity 0.3s ease;
            }

            .svg-label-background {
                &.svg-label-background-sold {
                    fill: rgba(64, 88, 90, 1);
                }

                &.svg-label-background-held {
                    fill: rgba(153, 184, 116, 1);
                }

                &.svg-label-background-phase-2 {
                    fill: rgba(242, 202, 100, 1);
                }
                
                &.svg-label-background-available {
                    fill: rgb(255, 255, 255);
                }
            }

            .svg-label-text {
                fill: #606e71;
                font-weight: 700;
                font-size: 13px;

                &.svg-label-text-sold {
                    fill: rgba(234, 246, 245, 1);
                    font-weight: 500;
                    font-family: Acumin;
                }

            }

            .svg-label-index-text {
                fill: #606e71;
                font-weight: 400;
                font-size: 27px;
            }
        }
    }
}
