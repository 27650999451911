.infobox {
    border-radius: 20px;

    .land-info {
        width: 160px;

        display: flex;
        align-items: center;
        flex-direction: column;

        .land-info-top {
            padding: 11px 0 11px 0;
            width: 100%;

            background-color: #2e3838;

            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 9px;

            color: #ffffff;

            .land-info-title {
                font-family: SALVAGER;
                font-size: 17px;
                letter-spacing: 0.5px;

                text-transform: uppercase;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .land-info-parameters {
                display: flex;
                align-items: center;
                justify-content: center;

                gap: 15px;

                .land-info-parameter {
                    font-size: 11px;
                    font-family: Inter;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-weight: 300;

                    .land-info-parameter-text-highlight {
                        font-weight: 500;
                    }
                }
            }

            .land-info-electric-transformer {
                text-transform: none;
                font-size: 10px;
            }
        }

        .land-info-bottom {
            width: 100%;

            color: #ffffff;

            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 8px;

            padding: 9px 0 9px 0;
            box-sizing: border-box;

            &.land-info-bottom-available {
                background-color: #cc7d61;
            }

            &.land-info-bottom-booked {
                background-color: #304a38;
            }

            &.land-info-bottom-sold {
                background-color: #bdc7c7;

                color: #2e3838 !important;
            }

            .land-info-status {
                font-family: SALVAGER;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 1px;

                text-transform: uppercase;

                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }
    }
}
