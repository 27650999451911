@media screen and (max-width: 600px) and (orientation: portrait) {
    .main{
        .enquire-window-wrapper-port{
            display: flex;
            height: 100vh;
            align-items: center;
            .enquire-window{
                position: relative;
                margin: 0 auto;
                right: auto;
                top: auto;
                .enquire-window-info{
                    .lot-size-status-block{
                        .price{
                            color: #7E9B5C;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .enquire-window-landscape{
            display: none;
        }
    }
}
@media screen and (max-width: 500px) and (orientation: portrait) {
    .main{
        .enquire-window{
            position: relative;
            margin: 40px auto;
            max-width: 300px;

            .enquire-window-info{
                .info-inner{
                    padding-right: 10px;
                }
                .lot-size-status-block{
                    .price{
                        color: #7E9B5C;
                        font-size: 14px;
                    }
                }
            }
        }
        .enquire-window-landscape{
            display: none;
        }
    }
}
@media screen and (max-width: 1000px) and (orientation: landscape){
    .main{
        .enquire-window{
            display: none;
        }
        .enquire-window-landscape{
            position: relative;
            margin: 80px auto;
            max-width: 800px;
            height: 400px;
            display: flex;
            background-color: #EAF6F5;
            border-radius: 8px;
            .contact-form-close {
                position: absolute;
                right: -10px;
                top: 10px;
                img {
                    width: 14px;
                }
                padding: 10px 13px;
            }
            .enquire-window-info{
                display: flex;
                .lot-size-status-block{
                    margin: 80px 20px 40px 20px;
                    .lot-size-status-block-inner{
                        display: flex;
                        .lot-span{
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 20px;
                            color: #40585A;
                            margin-right: 15px;
                            font-family: Inter;
                            white-space: nowrap;
                        }
                        .total-lot-size-span{
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 20px;
                            color: #40585A;
                            margin-left: 15px;
                            font-family: Inter;
                            display: flex;
                            white-space: nowrap;
                        }
                        .status-span{
                            background-color: #F2CA64;
                            padding: 5px 7px;
                            font-family: Inter;
                            font-size: 12px;
                            font-weight: 400;
                            text-transform: uppercase;
                            margin: 0 20px 0 80px;
                            white-space: nowrap;
                        }
                        .price{
                            color: #7E9B5C;
                            font-size: 15px;
                            margin: 0 0 0 20px;
                        }
                    }

                    .info-inner{
                        padding-right: 28px;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        display: flex;
                        margin: 60px 0 40px 0;
                        .info-inner-left{
                            margin: 8px 0 8px 0;
                            display: block;
                            div{
                                margin: 8px 0;
                                color: #40585A;
                                font-family: Inter;
                                font-size: 20px;
                            }
                        }
                        .info-inner-right{
                            margin: 8px 0 8px 28px;
                            display: block;
                            div{
                                margin: 8px 0;
                                color: #40585A;
                                font-family: Inter;
                                font-weight: 600;
                                font-size: 20px;
                                span{
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                    .contact-form-submit {
                        max-width: 180px;
                        width: 100%;
                        background-color: #40585A;
                        color: #F2CA64;
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 100;
                        text-transform: uppercase;
                        border: none;
                        border-radius: 4px;
                        padding: 10px 14px;
                        margin: 0 0 15px 0;
                        letter-spacing: 2.293px;
                    }
                }

                img{
                    width: 60%;
                    border-radius: 0 8px 8px 0;
                }
        }
        }
    }
}
@media screen and (max-width: 880px) and (orientation: landscape){
    .main{
        .enquire-window{
            display: none;
        }
        .enquire-window-wrapper{
            height: 100vh;
            display: flex;
            align-items: center;
            .enquire-window-landscape{
                position: relative;

                width: 100%;
                max-width: 650px;
                height: 250px;
                .contact-form-close {
                    right: 10px;
                    top: 10px;
                    background-color: #FFFFFF80;
                    border-radius: 50%;
                }
    
                .enquire-window-info{
                    .lot-size-status-block{
                        margin: 40px 20px 0 20px;
                        .lot-size-status-block-inner{
                            .status-span{
                                margin: 0 20px 0 30px;
                            }
                            .price{
                                color: #7E9B5C;
                                font-size: 15px;
                                margin: 0 0 0 20px;
                            }
                        }
                        .info-inner{
                            padding-right: 0px;
                            font-size: 12px;
                            margin: 0;
                            .info-inner-right{
                                margin: 8px 0 8px 10px;
                            }
                        }
    
                        .contact-form-submit {
                            max-width: 138px;
                            width: 100%;
                            font-family: Inter;
                            font-size: 12px;       
                        }
                    }
    
                    img{
                        width: 100%;
                        border-radius: 0 8px 8px 0;
                    }
            }
            }
        }
    }
}
// @media screen and (max-width: 670px) and (orientation: landscape){  
//     .main{
//         .enquire-window{
//             display: none;
//         }
//         .enquire-window-wrapper{
//             height: 100vh;
//             display: flex;
//             align-items: center;
//             .enquire-window-landscape{
//                 position: relative;

//                 width: 100%;
//                 max-width: 500px;
//                 height: 250px;
//                 .contact-form-close {
//                     right: 10px;
//                     top: 10px;
//                     background-color: #FFFFFF80;
//                     border-radius: 50%;
//                 }
    
//                 .enquire-window-info{
//                     .lot-size-status-block{
//                         margin: 40px 15px 0 20px;
//                         .lot-size-status-block-inner{
//                             .lot-span{
//                                 font-size: 16px;
//                                 margin-right: 5px;
//                             }
//                             .total-lot-size-span{
//                                 font-size: 10px;
//                                 margin-left: 5px;
//                             }
//                             .status-span{
//                                 margin: 0 20px 0 30px;
//                                 font-size: 8px;
//                             }
//                             .price{
//                                 color: #7E9B5C;
//                                 font-size: 7px;
//                                 margin: 0 0 0 20px;
//                             }
//                         }
//                         .info-inner{
//                             padding-right: 0px;
//                             font-size: 12px;
//                             margin: 10px 0;
//                             .info-inner-left{
//                                 div{
//                                     font-size: 14px;
//                                 }
//                             }
//                             .info-inner-right{
//                                 margin: 8px 0 8px 10px;
//                                 div{
//                                     font-size: 14px;
//                                 }
//                             }
//                         }
    
//                         .contact-form-submit {
//                             max-width: 138px;
//                             width: 100%;
//                             font-family: Inter;
//                             font-size: 12px;       
//                         }
//                     }
    
//                     img{
//                         width: 100%;
//                         border-radius: 0 8px 8px 0;
//                     }
//             }
//             }
//         }
//     }
// }
@media screen and (max-height: 484px) and (orientation: landscape){
    .main{
        .enquire-window{
            display: none;
        }
        .enquire-window-wrapper{
            height: 100vh;
            display: flex;
            align-items: center;
            .enquire-window-landscape{
                position: relative;

                width: 100%;
                max-width: 642px;
                height: 250px;
                .contact-form-close {
                    right: 10px;
                    top: 10px;
                    background-color: #FFFFFF80;
                    border-radius: 50%;
                }
    
                .enquire-window-info{
                    .lot-size-status-block{
                        margin: 40px 20px 0 20px;
                        .lot-size-status-block-inner{
                            .status-span{
                                margin: 0 20px 0 30px;
                            }
                            .price{
                                color: #7E9B5C;
                                font-size: 15px;
                                margin: 0 0 0 20px;
                            }
                        }
                        .info-inner{
                            padding-right: 0px;
                            font-size: 12px;
                            margin: 0;
                            .info-inner-right{
                                margin: 8px 0 8px 10px;
                            }
                        }
    
                        .contact-form-submit {
                            max-width: 138px;
                            width: 100%;
                            font-family: Inter;
                            font-size: 12px;       
                        }
                    }
    
                    img{
                        width: 100%;
                        border-radius: 0 8px 8px 0;
                    }
            }
            }
        }
    }
}
@media screen and (max-width: 1420px) {
    .main{
        .enquire-window {

            max-width: 355px;
            .enquire-window-info{
                .lot-size-status-block{
                    margin: 10px 20px 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    .lot-size-con{
                        .lot-span{
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 20px;
                            color: #40585A;
                            margin-right: 10px;
                            font-family: Inter;
                            white-space: nowrap;
                        }
                        .total-lot-size-span{
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 20px;
                            color: #40585A;
                            margin-left: 10px;
                            font-family: Inter;
                            white-space: nowrap;
                        }
                    }
                    .status-span{
                        background-color: #F2CA64;
                        padding: 5px 7px;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                    .price{
                        color: #7E9B5C;
                        font-size: 18px;
                    }
                }
                .info-inner{
                    .info-inner-left{
                        margin: 8px 0 8px 20px;
                        display: block;
                        div{
                            margin: 8px 0;
                        }
                    }
                    .info-inner-right{
                        margin: 8px 0 8px 28px;
                        display: block;
                        div{
                            margin: 8px 0;
                            color: #40585A;
                            font-family: Inter;
                            font-weight: 600;
                            span{
                                font-weight: 400;
                            }
                        }
                    }
                }
                .contact-form-submit {
                    max-width: 138px;
                    width: 100%;
        
                    background-color: #40585A;
        
                    color: #F2CA64;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 100;
                    text-transform: uppercase;
        
                    cursor: pointer;
        
                    transition: 0.2s;
        
                    border: none;
                    border-radius: 4px;
                    box-shadow: none;
    
                    padding: 10px 14px;
    
                    margin: 20px 0 15px 20px;
    
                    letter-spacing: 2.293px;
        
                    &:hover {
                        background-color: #2c3c3d;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1366px) {
    .main{
        .enquire-window {
            max-width: 300px;
            .enquire-window-info{
                .lot-size-status-block{
                    margin: 7px 20px 0 20px;
                    display: flex;
                    justify-content: space-between;
                    .lot-size-con{
                        .lot-span{
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 20px;
                            color: #40585A;
                            margin-right: 15px;
                            font-family: Inter;
                            white-space: nowrap;
                        }
                        .total-lot-size-span{
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            color: #40585A;
                            margin-left: 15px;
                            font-family: Inter;
                            white-space: nowrap;
                        }
                    }
                    .status-span{
                        background-color: #F2CA64;
                        padding: 5px 7px;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                    .price{
                        color: #7E9B5C;
                        font-size: 14px;
                    }
                }
                .info-inner{
                    .info-inner-left{
                        margin: 8px 0 8px 20px;
                        display: block;
                        div{
                            margin: 8px 0;
                        }
                    }
                    .info-inner-right{
                        margin: 8px 0 8px 28px;
                        display: block;
                        div{
                            margin: 8px 0;
                            color: #40585A;
                            font-family: Inter;
                            font-weight: 600;
                            span{
                                font-weight: 400;
                            }
                        }
                    }
                }
                .contact-form-submit {
                    max-width: 138px;
                    width: 100%;
        
                    background-color: #40585A;
        
                    color: #F2CA64;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 100;
                    text-transform: uppercase;
        
                    cursor: pointer;
        
                    transition: 0.2s;
        
                    border: none;
                    border-radius: 4px;
                    box-shadow: none;
    
                    padding: 10px 14px;
    
                    margin: 20px 0 15px 20px;
    
                    letter-spacing: 2.293px;
        
                    &:hover {
                        background-color: #2c3c3d;
                    }
                }
            }
        }
    }
}
@media screen and (max-height: 935px) {
    .main{
        .enquire-window {
            top: 2%;
            right: 2%;

            max-width: 355px;
            .enquire-window-info{
                .lot-size-status-block{
                    margin: 10px 20px 10px 20px;
                    .lot-span{
                        font-size: 18px !important;
                        font-weight: 700;
                        line-height: 20px;
                        color: #40585A;
                        margin-right: 10px !important;
                        font-family: Inter;
                    }
                    .total-lot-size-span{
                        font-size: 15px !important;
                        font-weight: 400;
                        line-height: 20px;
                        color: #40585A;
                        margin-left: 10px !important;
                        font-family: Inter;
                    }
                    .status-span{
                        background-color: #F2CA64;
                        padding: 5px 7px;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        text-transform: uppercase;
                        margin: 0 0 0 80px;
                    }
                    .price{
                        font-size: 18px !important; 
                    }
                }
                .info-inner{
                    .info-inner-left{
                        margin: 8px 0 8px 20px;
                        display: block;
                        div{
                            margin: 8px 0;
                        }
                    }
                    .info-inner-right{
                        margin: 8px 0 8px 28px;
                        display: block;
                        div{
                            margin: 8px 0;
                            color: #40585A;
                            font-family: Inter;
                            font-weight: 600;
                            span{
                                font-weight: 400;
                            }
                        }
                    }
                }
                .contact-form-submit {
                    max-width: 138px;
                    width: 100%;
        
                    background-color: #40585A;
        
                    color: #F2CA64;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 100;
                    text-transform: uppercase;
        
                    cursor: pointer;
        
                    transition: 0.2s;
        
                    border: none;
                    border-radius: 4px;
                    box-shadow: none;
    
                    padding: 10px 14px;
    
                    margin: 20px 0 15px 20px;
    
                    letter-spacing: 2.293px;
        
                    &:hover {
                        background-color: #2c3c3d;
                    }
                }
            }
        }
    }
}
@media screen and (max-height: 788px) {
    .main{
        .enquire-window {
            top: 2%;
            right: 2%;

            max-width: 300px;
            .enquire-window-info{
                .lot-size-status-block{
                    margin: 7px 20px 0 20px;
                    .lot-span{
                        font-size: 18px !important;
                        font-weight: 700;
                        line-height: 20px;
                        color: #40585A;
                        margin-right: 8px !important;
                        font-family: Inter;
                    }
                    .total-lot-size-span{
                        font-size: 13px !important;
                        font-weight: 400;
                        line-height: 20px;
                        color: #40585A;
                        margin-left: 8px !important;
                        font-family: Inter;
                    }
                    .status-span{
                        background-color: #F2CA64;
                        padding: 5px 7px;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        text-transform: uppercase;
                    }
                }
                .info-inner{
                    .info-inner-left{
                        margin: 8px 0 8px 20px;
                        display: block;
                        div{
                            margin: 8px 0;
                        }
                    }
                    .info-inner-right{
                        margin: 8px 0 8px 28px;
                        display: block;
                        div{
                            margin: 8px 0;
                            color: #40585A;
                            font-family: Inter;
                            font-weight: 600;
                            span{
                                font-weight: 400;
                            }
                        }
                    }
                }
                .contact-form-submit {
                    max-width: 138px;
                    width: 100%;
        
                    background-color: #40585A;
        
                    color: #F2CA64;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 100;
                    text-transform: uppercase;
        
                    cursor: pointer;
        
                    transition: 0.2s;
        
                    border: none;
                    border-radius: 4px;
                    box-shadow: none;
    
                    padding: 10px 14px;
    
                    margin: 20px 0 15px 20px;
    
                    letter-spacing: 2.293px;
        
                    &:hover {
                        background-color: #2c3c3d;
                    }
                }
            }
        }
    }
}
@media screen and (max-height: 680px) {
    .main{
        .enquire-window {
            top: 2%;
            right: 2%;

            max-width: 300px;
            .enquire-window-info{
                .lot-size-status-block{
                    margin: 7px 20px 0 20px;
                    .lot-span{
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #40585A;
                        margin-right: 15px;
                        font-family: Inter;
                    }
                    .total-lot-size-span{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #40585A;
                        margin-left: 15px;
                        font-family: Inter;
                    }
                    .status-span{
                        background-color: #F2CA64;
                        padding: 5px 7px;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        text-transform: uppercase;
                        margin: 0 0 0 50px;
                    }
                }
                .info-inner{
                    .info-inner-left{
                        margin: 8px 0 8px 20px;
                        display: block;
                        div{
                            margin: 6px 0;
                        }
                    }
                    .info-inner-right{
                        margin: 8px 0 8px 28px;
                        display: block;
                        div{
                            margin: 6px 0;
                            color: #40585A;
                            font-family: Inter;
                            font-weight: 600;
                            span{
                                font-weight: 400;
                            }
                        }
                    }
                }
                .contact-form-submit {
                    padding: 7px 10px;
    
                    margin: 5px 0 15px 20px;
                }
            }
        }
    }
}