.main {
    .menu {
        position: absolute;
        right: 30px;
        top: 30px;

        user-select: none;

        display: flex;
        flex-direction: column;

        border-radius: 20px;

        opacity: 90%;

        .lang-menu{
            display: flex;
            flex-direction: column;
            justify-content: center;

            .select{
                display: flex;
                align-items: center;
                text-align: center;
                cursor: pointer;
                color: #fff;
    
                span{
                    margin: 0 1vh;
                    font-size: 12px;
                }
    
                &.active{
                    justify-content: center;
    
                    .arrow-select{
                        transform: rotate(180deg);
                        transition: 0.3s;
                    }
                }
            }

            .triangle{
                max-width: 1.6vh;
                margin: 0 auto;
            }
    
            .select-dropdown{
                margin: -1px auto 0;
                padding: 0.4vh 0;
                display: flex;
                flex-direction: column;
                background-color: #fff;
                width: 9vh;
                cursor: pointer;
    
                .dropdown-element{
                    display: flex;
                    align-items: center;
                    margin: 2px 0;
                    width: 100%;
                    height: 2.4vh;
                    font-size: 1vh;
                    color: #40585A;
                    text-decoration: none;
                    
                    span{
                        margin: 0 0 0 1vh;
                    }
    
                    &:hover{
                        background-color: #F3F3F3;
                    }
                }
            }
        }
    }
}
