.main {
    .enquire-window {
        width: 100%;
        max-width: 400px;

        display: flex;
        top: auto !important;
        right: 2%;
        bottom: 4% !important;

        background-color: #eaf6f5;

        // box-sizing: border-box;

        position: absolute;

        transition: 0.2s;

        border-radius: 8px;

        overflow-y: auto !important;

        .contact-form-close {
            position: absolute;

            right: 8px;
            top: 8px;

            transition: 0.2s;

            cursor: pointer;

            img {
                width: 14px;
            }

            border-radius: 50%;
            background-color: #ffffff80;
            padding: 10px 13px;

            &:hover {
                opacity: 0.7;
            }
        }
        .enquire-window-info {
            img {
                width: 100%;
            }

            .lot-size-status-block {
                margin: 10px 20px 40px 20px;
                display: flex;
                justify-content: space-between;

                .lot-size-con{
                    .lot-span {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #40585a;
                        margin-right: 15px;
                        font-family: Inter;
                    }
                    .total-lot-size-span {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #40585a;
                        margin-left: 15px;
                        font-family: Inter;
                    }
                }
                .status-span {
                    background-color: #f2ca64;
                    padding: 5px 7px;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 20px;
                    text-transform: uppercase;
                }

                .price {
                    color: #7e9b5c;
                    font-size: 20px;
                    font-weight: 500 !important;
                }
            }

            .info-inner {
                padding-right: 28px;
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                display: flex;

                .info-inner-left {
                    margin: 8px 0 8px 28px;
                    display: block;

                    div {
                        margin: 8px 0;
                        color: #40585a;
                        font-family: Inter;
                    }
                }

                .info-inner-right {
                    margin: 8px 0 8px 28px;
                    display: block;

                    div {
                        margin: 8px 0;
                        color: #40585a;
                        font-family: Inter;
                        font-weight: 600;
                        span {
                            font-weight: 400;
                        }
                    }
                }
            }

            .info-desc {
                max-width: 375px;
                font-size: 10px;
                line-height: 18px;
                font-weight: 400;
                font-family: Inter;
                color: #40585a;
                margin: 29px 52px 0 28px;
            }

            .contact-form-submit {
                max-width: 138px;
                width: 100%;

                background-color: #40585a;

                color: #f2ca64;
                font-family: Inter;
                font-size: 12px;
                font-weight: 100;
                text-transform: uppercase;

                cursor: pointer;

                transition: 0.2s;

                border: none;
                border-radius: 4px;
                box-shadow: none;

                padding: 10px 14px;

                margin: 20px 0 15px 21px;

                letter-spacing: 2.293px;

                &:hover {
                    background-color: #2c3c3d;
                }
            }
        }
    }
    .enquire-window-landscape {
        display: none;
    }
}
