.infobox {
    position: fixed;
    z-index: 1000;

    min-width: 50px;
    max-width: 95%;
    background-color: #fff;

    border-radius: 8px;

    overflow: hidden;

    .infobox-text {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 11px 15px 11px 15px;

        font-size: 14px;
        color: #000000;
        font-weight: 500;

        text-transform: uppercase;
    }
}
