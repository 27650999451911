// @media screen and (max-width: 800px), screen and (max-height: 400px) {
//     .main {
//         .menu::before {
//             border-radius: 0 0 0 0 !important ;
//         }

//         .menu {
//             max-width: 100vw !important;
//             padding: 0 0 0 0 !important;
//             border-radius: 0% !important;

//             right: 2vh !important;
//             top: 8vh !important;

//             .menu-title {
//                 display: none !important;
//             }

//             .menu-buttons {
//                 // overflow-x: auto;
//                 flex-direction: row !important;
//                 margin-bottom: 0 !important;

//                 gap: 0 !important;

//                 .menu-button {
//                     // min-width: 95px !important;

//                     .menu-button-area {
//                         height: 45px;

//                         border: 0 !important;

//                         font-size: 11px;

//                         border-radius: 40px;

//                         .menu-button-area-text {
//                             text-align: center;
//                         }
//                     }

//                     &:first-of-type:last-of-type {
//                         .menu-button-area {
//                             border-radius: 0 !important;
//                         }
//                     }

//                     .menu-button-elements {
//                         border-radius: 12px 12px 0 0;

//                         .menu-button-element {
//                             &:first-of-type {
//                                 border-radius: 0 !important;
//                             }

//                             &:last-of-type {
//                                 border-radius: 0 !important;
//                             }

//                             &:first-of-type:last-of-type {
//                                 border-radius: 0 !important;
//                             }
//                         }
//                     }
//                 }
//             }

//             .menu-language {
//                 display: none !important;
//             }
//         }
//     }
// }

@media screen and (max-height: 400px) {
    .menu-button-area {
        height: 35px !important;
    }
}

@media screen and (max-height: 500px) {
    .menu {
        top: 90px !important;
        right: 25px !important;
    }
}

@media screen and (max-width: 1000px){
    .menu{
        top: 80px !important;
    }
}
