.main {
    .contact-form {
        width: 100%;
        max-width: 858px;

        display: flex;
        gap: 40px;
        justify-content: center;
        align-items: center;

        background-color: #EAF6F5;

        padding: 35px 28px 35px 28px;
        box-sizing: border-box;

        position: relative;

        transition: 0.2s;

        border-radius: 8px;

        .contact-form-close {
            position: absolute;

            right: 15px;
            top: 15px;

            transition: 0.2s;

            cursor: pointer;

            img {
                width: 15px;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .contact-form-left {
            max-width: 500px;

            display: flex;
            flex-direction: column;
            gap: 22px;

            min-width: 210px;
            min-height: 270px;

            .contact-form-left-group {
                display: flex;
                flex-direction: column;
                gap: 22px;
            }

            .contact-form-position-info {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            .contact-form-icons {
                display: flex;
                align-items: center;
                justify-content: space-between;

                width: 100%;

                img {
                    transform: scale(0.9);
                }
            }


            .lot-size-block{
                color: #40585A;
                font-size: 24px;
                font-family: Inter;
                span{
                    margin: 0 10px;
                }
                .price{
                    color: #7e9b5c;
                    font-size: 20px;
                }
                .status-span{
                    background-color: #f2ca64;
                    padding: 5px 7px;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 20px;
                    text-transform: uppercase;
                }
            }
            .info-inner{
                display: flex;
                .info-inner-block{
                    margin: 0 11px;
                    div{
                        margin: 8px 0;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: Inter;
                    }
                }
            }
        }

        .contact-form-right {
            width: 300px;
            form {
                display: flex;
                flex-direction: column;
                gap: 15px;
    
                .contact-form-field {
                    width: 100%;
    
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
    
                    .contact-form-field-label {
                        color: #374151;
    
                        font-family: Inter;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
    
                    .contact-form-field-input-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 10px;
    
                        width: 100%;
    
                        padding: 10px 12px 10px 12px;
                        box-sizing: border-box;
    
                        border: 1px solid #949494;
                        background-color: #FFF;
    
                        transition: 0.2s;
    
                        border-radius: 3px;
    
                        &:hover {
                            border: 1px solid rgba(255, 255, 255, 0.603);
                        }
    
                        .contact-form-field-icon {
                            width: 18px;
                        }
    
                        input {
                            width: 100%;
                            min-width: 200px;
    
                            background-color: transparent;
                            border: none;
    
                            color: #606E71;
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
    
                            &::placeholder {
                                color: #606E71;
                            }
    
                            &:focus {
                                outline: none;
                            }
                        }
    
                        // select {
                        //     -webkit-appearance: none;
                        //     -moz-appearance: none;
                        //     background: transparent;
                        //     background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                        //     background-repeat: no-repeat;
                        //     background-position-x: 100%;
                        //     background-position-y: 5px;
                        //     border: 1px solid #dfdfdf;
                        //     border-radius: 2px;
                        //     margin-right: 2rem;
                        //     padding: 1rem;
                        //     padding-right: 2rem;
                        // }
                    }
                }
    
                .contact-form-submit {
                    width: 100%;
                    height: 50px;
    
                    margin-top: 10px;
    
                    background-color: #40585A;
    
                    color: #F2CA64;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                    letter-spacing: 3px;
                    text-transform: uppercase;
    
                    cursor: pointer;
    
                    transition: 0.2s;
    
                    border: none;
                    border-radius: 4px;
                    box-shadow: none;
    
                    &:hover {
                        background-color: #cc8368;
                    }
                }
            }
        }

        .contact-form-title {
            color: #40585A;

            font-family: Inter;
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 1.5px;

            text-transform: uppercase;
        }

        .contact-form-info {
            width: 100%;

            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            font-family: Inter;
            font-size: 15px;

            .contact-form-info-label {
                width: 50%;
                font-weight: 500;

                color: #40585A;
            }

            .contact-form-info-value {
                width: 50%;
                color: #40585A;

                font-weight: 300;
            }
        }

        .contact-form-text {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
            color: #ffffff;
        }

        .contact-form-text-transformer {
            font-size: 13px;
        }

        .contact-form-electric-transformer {
            color: #cc7d61;
        }

        // form {
        //     display: flex;
        //     flex-direction: column;
        //     gap: 15px;

        //     .contact-form-field {
        //         width: 100%;

        //         display: flex;
        //         flex-direction: column;
        //         gap: 5px;

        //         .contact-form-field-label {
        //             color: #374151;

        //             font-family: Inter;
        //             font-size: 13px;
        //             font-style: normal;
        //             font-weight: 400;
        //             line-height: 20px;
        //         }

        //         .contact-form-field-input-wrapper {
        //             display: flex;
        //             align-items: center;
        //             gap: 10px;

        //             width: 100%;

        //             padding: 10px 12px 10px 12px;
        //             box-sizing: border-box;

        //             border: 1px solid #949494;
        //             background-color: #FFF;

        //             transition: 0.2s;

        //             border-radius: 3px;

        //             &:hover {
        //                 border: 1px solid rgba(255, 255, 255, 0.603);
        //             }

        //             .contact-form-field-icon {
        //                 width: 18px;
        //             }

        //             input {
        //                 width: 100%;

        //                 background-color: transparent;
        //                 border: none;

        //                 color: #606E71;
        //                 font-family: Inter;
        //                 font-size: 16px;
        //                 font-style: normal;
        //                 font-weight: 400;

        //                 &::placeholder {
        //                     color: #606E71;
        //                 }

        //                 &:focus {
        //                     outline: none;
        //                 }
        //             }

        //             // select {
        //             //     -webkit-appearance: none;
        //             //     -moz-appearance: none;
        //             //     background: transparent;
        //             //     background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        //             //     background-repeat: no-repeat;
        //             //     background-position-x: 100%;
        //             //     background-position-y: 5px;
        //             //     border: 1px solid #dfdfdf;
        //             //     border-radius: 2px;
        //             //     margin-right: 2rem;
        //             //     padding: 1rem;
        //             //     padding-right: 2rem;
        //             // }
        //         }
        //     }

        //     .contact-form-submit {
        //         width: 100%;
        //         height: 50px;

        //         margin-top: 10px;

        //         background-color: #40585A;

        //         color: #F2CA64;
        //         font-family: Inter;
        //         font-size: 14px;
        //         font-style: normal;
        //         font-weight: 200;
        //         line-height: normal;
        //         letter-spacing: 3px;
        //         text-transform: uppercase;

        //         cursor: pointer;

        //         transition: 0.2s;

        //         border: none;
        //         border-radius: 4px;
        //         box-shadow: none;

        //         &:hover {
        //             background-color: #cc8368;
        //         }
        //     }
        // }

        .contact-form-status {
            display: flex;
            flex-direction: column;
            align-items: center;

            gap: 26px;

            padding: 30px 0 40px 0;

            .contact-form-status-title {
                color: #40585A;
                font-family: Inter;
                font-size: 22px;
                font-weight: 400;
                letter-spacing: 2px;
                text-transform: uppercase;

                text-align: center;
            }

            .contact-form-status-desc {
                width: 75%;

                color: #40585A;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 1px;
            }
        }
    }
}
