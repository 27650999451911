.modal {
    position: fixed;

    width: 100%;
    height: 100%;

    padding: 70px 100px 70px 100px;
    box-sizing: border-box;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: #606E7199;

    overflow: hidden;

    padding: 0;

    .modal-content {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        pointer-events: none;
    }

    .modal-exit {
        position: absolute;
        z-index: 5;

        top: 35px;
        right: 69px;

        cursor: pointer;

        display: flex;
        // align-items: center;
        // justify-content: center;

        gap: 10px;
        border-radius: 20px;
        padding: 10px 18px 9px 18px;
        border: white;
        font-size: 19px;

        transition: 0.3s;

        svg {
            width: 20px;
            height: 20px;

            rect {
                fill: #ffffff;
                transition: 0.3s;
            }
        }
    }
}
