.main {
    .golf-estates {
        .landmark {
            filter: drop-shadow(0px 0px 4px rgba(5, 5, 5, 0.25)) !important;
            opacity: 1;
            transition: 0.2s;

            pointer-events: bounding-box !important;

            // &:hover {
            //     opacity: 100%;
            // }
        }

        .golf-estates-switches {
            position: absolute;
            z-index: 2;

            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: 6px;

            right: 30px;
            top: 20px;

            width: 120px;
        }

        .golf-estates-area {

            path {
                stroke-width: 1.35 !important;
            }

            &.area-available {
                path {
                    fill: rgba(255, 255, 255, 0.2) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    &:hover {
                        fill: rgba(255, 255, 255, 0.5) !important;
                    }
                }
            }
            &.area-available.active {
                path {
                    fill: rgba(255, 255, 255, 0.5) !important;
                }
            }

            &.area-held {
                path {
                    fill: rgba(186, 181, 181, 0.5) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    &:hover {
                        fill: rgba(186, 181, 181, 0.7) !important;
                    }
                }
            }
            &.area-held.active {
                path {
                    fill: rgba(186, 181, 181, 0.7) !important;
                }
            }

            &.area-sold {
                path {
                    fill: rgba(96, 110, 113, 0.4) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    // &:hover {
                    //     fill: rgba(69, 97, 102, 0.4) !important;
                    // }
                }
            }

            &.area-phase-2 {
                path {
                    fill: rgba(255, 255, 255, 0.2) !important;
                    stroke: rgba(242, 202, 100, 1) !important;
                    stroke-width: 2.7 !important;

                    &:hover {
                        fill: rgba(243, 203, 99, 0.8) !important;
                        stroke: rgba(242, 202, 100, 1) !important;
                    }
                }
            }
            &.area-phase-2.active {
                path {
                    fill: rgba(243, 203, 99, 0.8) !important;
                    stroke: rgba(242, 202, 100, 1) !important;
                }
            }

            //jp
            &.area-分譲中 {
                path {
                    fill: rgba(255, 255, 255, 0.2) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    &:hover {
                        fill: rgba(255, 255, 255, 0.5) !important;
                    }
                }
            }
            &.area-分譲中.active {
                path {
                    fill: rgba(255, 255, 255, 0.5) !important;
                }
            }

            &.area-商談中 {
                path {
                    fill: rgba(186, 181, 181, 0.5) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    &:hover {
                        fill: rgba(186, 181, 181, 0.7) !important;
                    }
                }
            }
            &.area-商談中.active {
                path {
                    fill: rgba(186, 181, 181, 0.7) !important;
                }
            }

            &.area-売却済み {
                path {
                    fill: rgba(96, 110, 113, 0.4) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    // &:hover {
                    //     fill: rgba(69, 97, 102, 0.4) !important;
                    // }
                }
            }

            &.area-フェーズ2 {
                path {
                    fill: rgba(255, 255, 255, 0.2) !important;
                    stroke: rgba(242, 202, 100, 1) !important;
                    stroke-width: 2.7 !important;

                    &:hover {
                        fill: rgba(243, 203, 99, 0.8) !important;
                        stroke: rgba(242, 202, 100, 1) !important;
                    }
                }
            }
            &.area-フェーズ2.active {
                path {
                    fill: rgba(243, 203, 99, 0.8) !important;
                    stroke: rgba(242, 202, 100, 1) !important;
                }
            }


            //ch1
            &.area-銷售中 {
                path {
                    fill: rgba(255, 255, 255, 0.2) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    &:hover {
                        fill: rgba(255, 255, 255, 0.5) !important;
                    }
                }
            }
            &.area-銷售中.active {
                path {
                    fill: rgba(255, 255, 255, 0.5) !important;
                }
            }

            &.area-保留 {
                path {
                    fill: rgba(186, 181, 181, 0.5) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    &:hover {
                        fill: rgba(186, 181, 181, 0.7) !important;
                    }
                }
            }
            &.area-保留.active {
                path {
                    fill: rgba(186, 181, 181, 0.7) !important;
                }
            }

            &.area-已售出 {
                path {
                    fill: rgba(96, 110, 113, 0.4) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    // &:hover {
                    //     fill: rgba(69, 97, 102, 0.4) !important;
                    // }
                }
            }

            &.area-第二期 {
                path {
                    fill: rgba(255, 255, 255, 0.2) !important;
                    stroke: rgba(242, 202, 100, 1) !important;
                    stroke-width: 2.7 !important;

                    &:hover {
                        fill: rgba(243, 203, 99, 0.8) !important;
                        stroke: rgba(242, 202, 100, 1) !important;
                    }
                }
            }
            &.area-第二期.active {
                path {
                    fill: rgba(243, 203, 99, 0.8) !important;
                    stroke: rgba(242, 202, 100, 1) !important;
                }
            }


            //ch2
            &.area-销售中 {
                path {
                    fill: rgba(255, 255, 255, 0.2) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    &:hover {
                        fill: rgba(255, 255, 255, 0.5) !important;
                    }
                }
            }
            &.area-销售中.active {
                path {
                    fill: rgba(255, 255, 255, 0.5) !important;
                }
            }

            &.area-保留 {
                path {
                    fill: rgba(186, 181, 181, 0.5) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    &:hover {
                        fill: rgba(186, 181, 181, 0.7) !important;
                    }
                }
            }
            &.area-保留.active {
                path {
                    fill: rgba(186, 181, 181, 0.7) !important;
                }
            }

            &.area-已售出 {
                path {
                    fill: rgba(96, 110, 113, 0.4) !important;
                    stroke: rgba(255, 255, 255, 1) !important;

                    // &:hover {
                    //     fill: rgba(69, 97, 102, 0.4) !important;
                    // }
                }
            }

            &.area-第二期 {
                path {
                    fill: rgba(255, 255, 255, 0.2) !important;
                    stroke: rgba(242, 202, 100, 1) !important;
                    stroke-width: 2.7 !important;

                    &:hover {
                        fill: rgba(243, 203, 99, 0.8) !important;
                        stroke: rgba(242, 202, 100, 1) !important;
                    }
                }
            }
            &.area-第二期.active {
                path {
                    fill: rgba(243, 203, 99, 0.8) !important;
                    stroke: rgba(242, 202, 100, 1) !important;
                }
            }
        }

        .pop-up {
            right: 185px;
            top: 21px;
        }
    }
}
